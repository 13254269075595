<template>
  <v-dialog v-model="show" persistent transition="dialog-bottom-transition">
    <v-form ref="form" v-model="valid" :lazy-validation="lazy">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            Nuevo Turno
            <v-spacer></v-spacer>
            <h5>Día: {{ dia }} - Hora: {{ hora }}</h5>
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-row dense>
            <v-col cols="9" sm="5" md="5">
              <v-text-field
                v-model="paciente.dni"
                prepend-icon="mdi-account"
                hint="Ingrese el DNI del Paciente y apriete enter o toque fuera de este cuadro de texto. Si el paciente ya existe, se verán sus datos. Si el paciente no existe, complete los datos y al guardar el turno el paciente quedará registrado. Puede también buscar el paciente por apellido y nombre haciendo click en la lupa"
                label="D.N.I"
                dense
                required
                @keypress="onlyNumber"
                @keypress.enter="$event.target.blur()"
                @blur="buscarPacienteDni"
                :rules="requiredRules"
                maxlength="9"
              ></v-text-field>
            </v-col>
            <v-col cols="2" sm="2" md="2">
              <v-btn color="primary" @click="buscarPaciente">
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="paciente.apellidos"
                prepend-icon="mdi-account"
                label="Apellidos"
                maxlength="50"
                :rules="requiredRules"
                @blur="upper"
                dense
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="paciente.nombres"
                prepend-icon="mdi-account"
                label="Nombres"
                maxlength="50"
                :rules="requiredRules"
                @blur="upperNombres"
                dense
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="paciente.fechaNac"
                :value="paciente.fechaNac"
                label="Fecha de Nacimiento"
                prepend-icon="mdi-calendar"
                type="date"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-select
                :items="sexo"
                v-model="paciente.sexo"
                item-text="paciente.sexo"
                item-value="paciente.sexo"
                label="Sexo"
                dense
                prepend-icon="mdi-account"
              ></v-select>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="paciente.direccion"
                prepend-icon="mdi-map-marker"
                label="Direccion"
                maxlength="80"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="paciente.localidad"
                prepend-icon="mdi-map-marker"
                label="Localidad"
                maxlength="80"
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col
              cols="12"
              sm="4"
              md="4"
              v-if="
                !this.$store.state.usuario.institucion.notificaWA ||
                this.$store.state.usuario.institucion.idInstitucion === 114 ||
                this.$store.state.usuario.institucion.idInstitucion === 270
              "
            >
              <v-text-field
                v-model="paciente.celular"
                prepend-icon="mdi-cellphone"
                label="Celular"
                maxlength="50"
                dense
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="4"
              md="4"
              v-if="
                this.$store.state.usuario.institucion.notificaWA &&
                this.$store.state.usuario.institucion.idInstitucion !== 114 &&
                this.$store.state.usuario.institucion.idInstitucion !== 270
              "
            >
              <v-text-field
                v-model="paciente.celular"
                prepend-icon="mdi-cellphone"
                label="Celular"
                maxlength="50"
                hint="Ingrese el celular con la característica, sin 0, sin 15 y sin guiones. Ej: 1135036549"
                dense
                :rules="isCMSM ? celularCMSM : celularRules"
                @blur="trimCelular()"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-text-field
                v-model="paciente.telefono"
                prepend-icon="mdi-phone"
                label="Telefono"
                maxlength="50"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-text-field
                v-model="paciente.mail"
                prepend-icon="mdi-email"
                label="E-mail"
                :rules="emailRules"
                dense
                maxlength="80"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="paciente.observaciones"
                prepend-icon="mdi-account"
                label="Observaciones del Paciente"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="turno.observaciones"
                prepend-icon="mdi-account"
                label="Observaciones del Turno"
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="4" md="4">
              <v-select
                :items="obrasSociales"
                item-text="nombre"
                item-value="idObraSocial"
                prepend-icon="mdi-calendar-multiple"
                v-model="obraSocial"
                :label="`${resolveLabel}`"
                dense
              ></v-select>
            </v-col>
            <v-col cols="12" sm="4" md="3">
              <v-select
                :items="
                  $store.state.usuario.institucion.idInstitucion === 195
                    ? intervalosCMSM
                    : intervalos
                "
                prepend-icon="mdi-calendar-multiple"
                v-model="turno.cantIntervalos"
                label="Cantidad turnos a ocupar"
                :disabled="entreTurno == 1"
                dense
              ></v-select>
            </v-col>
            <v-col cols="12" sm="4" md="3">
              <v-select
                :items="semanas"
                prepend-icon="mdi-calendar-multiple"
                v-model="turno.cantSemanas"
                label="Cantidad semanas consecutivas"
                dense
              ></v-select>
            </v-col>
            <v-col
              class="cada-dos-semanas"
              cols="12"
              sm="4"
              md="2"
              v-if="turno.cantSemanas > 1"
            >
              <v-checkbox
                v-model="turno.cadaDosSemanas"
                label="Cada 2 semanas"
                color="primary"
                dense
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row dense v-if="utilizaVideoLlamada">
            <v-col cols="8" md="4" sm="4" class="py-0 my-0">
              <v-switch
                class="py-0 my-0"
                dense
                v-model="vllamada"
                label="Es Videoconsulta"
              ></v-switch>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <h6 style="color: indigo" v-if="paciente.idPaciente != null">
            {{ tipoPaciente }}
          </h6>
          <v-spacer></v-spacer>
          <v-btn color="success" :disabled="!valid" @click.stop="guardarTurno"
            >Guardar</v-btn
          >

          <v-btn color="error" @click.stop="show = false">Salir</v-btn>
        </v-card-actions>
        <BuscarPacienteTurno
          :visible="showBuscarPaciente"
          @close="showBuscarPaciente = false"
          @seleccionarPaciente="seleccionarPaciente($event)"
        ></BuscarPacienteTurno>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import axios from "../../axios-auth.js";
import BuscarPacienteTurno from "../Turnos/BuscarPacienteTurno.vue";

export default {
  data: (vm) => ({
    horaTurno: null,
    paciente: {
      dni: null,
      apellidos: null,
      nombres: null,
      fechaNac: null,
      sexo: null,
      direccion: null,
      localidad: null,
      mail: null,
      telefono: null,
      celular: null,
      observaciones: null,
      antecedentes: "",
      idPaciente: null,
      antecedentesFamiliares: null,
      esGestante: false,
    },
    vllamada: false,
    utilizaVideoLlamada: false,
    showBuscarPaciente: false,
    turno: {
      idTurno: null,
      cantIntervalos: null,
      observaciones: null,
      cantSemanas: null,
      cadaDosSemanas: false,
    },
    intervalos: [1, 2, 3, 4, 5, 6, 7, 8],
    intervalosCMSM: [1],
    semanas: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
    sexo: ["FEMENINO", "MASCULINO", "OTRO"],
    valid: false,
    lazy: false,
    tipoPaciente: null,
    obrasSociales: [],
    obraSocial: "",
    requiredRules: [(v) => !!v || "Dato obligatorio"],
    emailRules: [
      (v) =>
        !v ||
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
        "E-mail inválido",
    ],
    celularRules: [(v) => !v || v.length == 10 || "Debe tener 10 caracteres"],
    celularCMSM: [
      (v) => !v || v.length == 10 || "Debe tener 10 caracteres",
      (v) => !!v || "Dato obligatorio",
    ],
  }),

  props: [
    "visible",
    "dia",
    "hora",
    "idProfesional",
    "tiempoTurno",
    "entreTurno",
    "tipoTurno",
  ],

  components: {
    BuscarPacienteTurno,
  },
  computed: {
    show: {
      get() {
        if (this.visible) this.setearModels();
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$refs.form.resetValidation();
          this.vaciarModels();
          this.obraSocial = this.obrasSociales[0].idObraSocial;
          this.paciente.dni = "";
          document.getElementsByClassName("v-dialog--active")[0].scrollTop = 0;
          this.$emit("close");
        }
      },
    },
    resolveLabel() {
      return this.$store.state.usuario.institucion.esEmpresa
        ? "Sede"
        : "Obra Social";
    },
    isCMSM() {
      return this.$store.state.usuario.institucion.idInstitucion === 195;
    },
  },

  methods: {
    trimCelular() {
      this.paciente.celular = this.paciente.celular.toString().trim();
    },
    validarFechaNacimiento() {
      if (this.paciente.fechaNac == null) return true;
      else {
        if (this.paciente.fechaNac.toString().length > 10) return false;
        else return true;
      }
    },
    guardarTurno() {
      this.$swal({
        title: "Guardar Turno",
        text: "¿Desea registrar el turno?",
        icon: "success",
        type: "success",
        showCancelButton: true,
        background: "#ececec",
        confirmButtonText: "Si",
        cancelButtonText: "No",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then(async (result) => {
        if (result.value) {
          //this.$swal('Turno g', 'You successfully deleted this file', 'success')

          if (
            this.vllamada == true &&
            (this.paciente.mail == "" || this.paciente.mail == null)
          ) {
            this.showAlert({
              timer: 3500,
              icon: "error",
              title:
                "Si el turno es del tipo videoconsulta, debe completar el email del paciente, para poder enviarle el link de la reunión.",
              vm: this,
            });
            return;
          }

          const datosTurno = {
            idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
            dniPaciente: this.paciente.dni,
            apellidoPaciente: this.paciente.apellidos,
            nombrePaciente: this.paciente.nombres,
            fechaNacimientoString: this.paciente.fechaNac,
            sexo: this.paciente.sexo,
            direccion: this.paciente.direccion,
            localidad: this.paciente.localidad,
            celular: this.paciente.celular.trim(),
            telefono: this.paciente.telefono.trim(),
            mail: this.paciente.mail.trim(),
            observaciones: this.paciente.observaciones,
            antecedentes: this.paciente.antecedentes,
            antecedentesFamiliares: this.paciente.antecedentesFamiliares,
            esGestante: this.paciente.esGestante,
            idPaciente: this.paciente.idPaciente,
            fechaTurnoString: this.dia,
            horaInicioString: this.hora,
            cantidadIntervalos: this.turno.cantIntervalos,
            duracionTurno: this.turno.cantIntervalos * this.tiempoTurno,
            idObraSocial: this.obraSocial,
            idprofesional: this.idProfesional,
            estadoTurno: 1,
            esWeb: false,
            entreTurno: this.entreTurno,
            observacionesTurno: this.turno.observaciones,
            esVideoLlamada: this.vllamada,
            cantSemanas: this.turno.cantSemanas,
            usuarioAlta: this.$store.state.usuario.usuario,
            cadaDosSemanas: this.turno.cadaDosSemanas,
          };
          if (
            datosTurno.apellidoPaciente == null ||
            datosTurno.apellidoPaciente == ""
          ) {
            this.showAlert({
              timer: 2500,
              icon: "error",
              title: "Faltan completar datos obligatorios",
              vm: this,
            });
            return;
          }
          let isValidDate = this.validarFechaNacimiento();
          if (!isValidDate) {
            this.showAlert({
              icon: "error",
              title: "La fecha de nacimiento no tiene un formato válido.",
              vm: this,
              timer: 2800,
            });
            return;
          }
          const { data } = await axios.get("Turnos/VerificarTurnoPacienteDia", {
            params: {
              idPaciente: datosTurno.idPaciente,
              fechaTurnoString: datosTurno.fechaTurnoString,
              idProfesional: datosTurno.idprofesional,
            },
          });
          if (data) {
            if (this.$store.state.usuario.institucion.idInstitucion === 195) {
              this.showAlert({
                icon: "error",
                title: "El paciente ya posee un turno registrado para hoy.",
                vm: this,
                timer: 2800,
              });
              return;
            } else {
              this.$swal({
                title: "Paciente con turno en el día",
                text: "El paciente ya posee un turno registrado para hoy. ¿Desea de todos modos registrar el turno?",
                icon: "success",
                type: "success",
                showCancelButton: true,
                background: "#ececec",
                confirmButtonText: "Si",
                cancelButtonText: "No",
                showCloseButton: true,
                showLoaderOnConfirm: true,
              }).then(async (result) => {
                if (result.value) {
                  this.grabarTurno(datosTurno);
                }
              });
            }
          } else {
            this.grabarTurno(datosTurno);
          }
        } else {
          //this.$swal('Cancelled', 'Your file is still intact', 'info')
        }
      });
    },
    grabarTurno(datosTurno) {
      let self = this;
      axios
        .post("/Turnos/GuardarTurno", datosTurno)
        .then((response) => {
          if (response.data == "Turnos Existentes") {
            self.showAlert({
              timer: 2800,
              icon: "error",
              title:
                "Existen turnos ya reservados dentro de la duración del turno que quiere reservar",
              vm: self,
            });
          } else {
            if (response.data == "Turnos Existentes Semana") {
              self.showAlert({
                timer: 2800,
                icon: "error",
                title: "Existen turnos en algunas de las semanas siguientes",
                vm: self,
              });
            } else {
              if (response.data == "Tope") {
                self.showAlert({
                  timer: 2800,
                  icon: "error",
                  title: `${
                    self.$store.state.usuario.institucion.esEmpresa
                      ? "El profesional no atiende en la sede seleccionada"
                      : "Supera la cantidad máxima de turnos diarios para la obra social"
                  }`,
                  vm: self,
                });
              } else {
                if (response.data == "Reservado") {
                  self.showAlert({
                    timer: 2800,
                    icon: "error",
                    title:
                      "Disculpe, el turno acaba de ser tomado. Reserve otro por favor",
                    vm: self,
                  });
                } else {
                  self.$refs.form.resetValidation();
                  self.obraSocial = this.obrasSociales[0].idObraSocial;
                  self.paciente.dni = "";
                  document.getElementsByClassName(
                    "v-dialog--active"
                  )[0].scrollTop = 0;
                  if (self.vllamada == false) {
                    self.showAlert({
                      timer: 2200,
                      icon: "success",
                      title: "Turno reservado",
                      vm: self,
                    });
                  } else {
                    self.showAlert({
                      timer: 3200,
                      icon: "success",
                      title:
                        "Turno reservado. Se enviará un email al paciente con las instrucciones para la videoconsulta.",
                      vm: self,
                    });
                  }
                  self.vaciarModels();
                  self.$emit("turnoRegistrado");
                }
              }
            }
          }
        })
        .catch((error) => {
          self.showAlert({
            timer: 2000,
            icon: "error",
            title: "El turno no pudo ser reservado",
            vm: this,
          });
        });
    },
    seleccionarPaciente(item) {
      this.paciente.dni = item.nrodocumento;
      this.paciente.apellidos = item.apellidos;
      this.paciente.nombres = item.nombres;
      if (item.fechanacimiento == "0001-01-01T00:00:00")
        this.paciente.fechaNac = "";
      else this.paciente.fechaNac = item.fechanacimiento.substring(0, 10);
      this.paciente.sexo = item.sexo;
      this.paciente.localidad = item.localidad;
      this.paciente.direccion = item.direccion;
      this.paciente.celular = item.celular;
      this.paciente.mail = item.email;
      this.paciente.telefono = item.telefono;
      this.paciente.observaciones = item.observaciones;
      this.paciente.antecedentes = item.antecedentes;
      this.paciente.antecedentesFamiliares = item.antecedentesFamiliares;
      this.paciente.esGestante = item.esGestante;
      this.paciente.idPaciente = item.idpaciente;
      this.tipoPaciente = "Paciente Existente";
      this.showBuscarPaciente = false;

      axios
        .get("/Paciente/BuscarDatosAfiliatorios?", {
          params: {
            idPaciente: item.idpaciente,
          },
        })
        .then((response) => {
          if (response.data != null && response.data != "")
            this.obraSocial = response.data;
        });
    },

    buscarPacienteDni() {
      if (isNaN(this.paciente.dni)) {
        this.showAlert({
          timer: 3200,
          icon: "error",
          title: "El D.N.I. debe estar compuesto sólo por números.",
          vm: this,
        });
        this.paciente.dni = "";
        this.paciente.idPaciente = 0;
        return;
      }
      if (this.paciente.dni != "" && this.paciente.dni != null)
        this.paciente.dni = parseInt(this.paciente.dni);

      const pacientesData = {
        dni: this.paciente.dni,
        idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
      };
      let self = this;
      axios
        .get("/Paciente/GetPacienteDniTurno?", {
          params: {
            dni: pacientesData.dni,
            idInstitucion: pacientesData.idInstitucion,
          },
        })
        .then(function (response) {
          if (response.data != "" && response.data != null) {
            self.paciente.apellidos = response.data.apellidos;
            self.paciente.nombres = response.data.nombres;
            self.paciente.idPaciente = response.data.idpaciente;
            if (response.data.fechanacimiento == "0001-01-01T00:00:00")
              self.paciente.fechaNac = "";
            else
              self.paciente.fechaNac = response.data.fechanacimiento.substring(
                0,
                10
              );
            self.paciente.sexo = response.data.sexo;
            self.paciente.direccion = response.data.direccion;
            self.paciente.localidad = response.data.localidad;
            self.paciente.telefono = response.data.telefono;
            self.paciente.celular = response.data.celular;
            self.paciente.mail = response.data.email;
            self.paciente.antecedentes = response.data.antecedentes;
            self.paciente.antecedentesFamiliares =
              response.data.antecedentesFamiliares;
            self.paciente.esGestante = response.data.esGestante;
            self.paciente.observaciones = response.data.observaciones;
            self.tipoPaciente = "Paciente Existente";
            if (response.data.idObraSocial != null)
              self.obraSocial = response.data.idObraSocial;
          } else {
            self.vaciarModels();
            self.paciente.idPaciente = 0;
            self.tipoPaciente = "Paciente Nuevo";
            if (
              self.$store.state.usuario.institucion.idInstitucion === 114 ||
              self.$store.state.usuario.institucion.idInstitucion === 270
            ) {
              self.paciente.celular = "549";
            }
          }
        })
        .catch(function (error) {});
    },
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    upper() {
      if (this.paciente.apellidos != null)
        this.paciente.apellidos = this.paciente.apellidos
          .toString()
          .toUpperCase();
    },
    upperNombres() {
      if (this.paciente.nombres != null)
        this.paciente.nombres = this.paciente.nombres.toString().toUpperCase();
    },
    buscarPaciente() {
      this.showBuscarPaciente = true;
    },
    vaciarModels() {
      this.paciente.apellidos = "";
      this.paciente.nombres = "";
      this.paciente.direccion = "";
      this.paciente.localidad = "";
      this.paciente.celular = "";
      this.paciente.telefono = "";
      this.paciente.mail = "";
      this.paciente.observaciones = "";
      this.paciente.antecedentes = "";
      this.paciente.antecedentesFamiliares = "";
      this.paciente.esGestante = false;
      this.paciente.fechaNac = null;
      this.paciente.sexo = null;
      this.paciente.idPaciente = null;
      this.turno.cantIntervalos = 1;
      this.turno.observaciones = "";
      this.turno.cantSemanas = 1;
      this.turno.cadaDosSemanas = false;
      //this.utilizaVideoLlamada = false;
      this.vllamada = false;
      //this.obraSocial = this.obrasSociales[0].idObraSocial;
    },

    showAlert(alertInfo) {
      alertInfo.vm.$swal.fire({
        icon: alertInfo.icon,
        title: alertInfo.title,
        showConfirmButton: false,
        background: "#ececec",
        timer: alertInfo.timer,
      });
    },
    setearModels() {
      this.utilizaVideoLlamada =
        this.$store.state.usuario.institucion.utilizaVideoLlamada;
      this.turno.cantSemanas = 1;
      this.turno.cadaDosSemanas = false;
      const osData = {
        idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
        idProfesional: this.idProfesional,
      };

      let self = this;
      const osEndpoint =
        this.$store.state.usuario.institucion.idInstitucion === 285
          ? "/ObraSocial/GetObraSocialActivasDeInstSinTope?"
          : "/ObraSocial/GetObraSocialActivasDeInst?";
      axios
        .get(osEndpoint, {
          params: {
            idInstitucion: osData.idInstitucion,
            idProfesional: osData.idProfesional,
          },
        })
        .then((response) => {
          self.obrasSociales = response.data;

          if (this.$store.state.usuario.institucion.idInstitucion == 18) {
            self.obraSocial = 35;
          } else if (
            this.$store.state.usuario.institucion.idInstitucion == 170
          ) {
            self.obraSocial = 878;
          } else self.obraSocial = self.obrasSociales[0].idObraSocial;
        })

        .catch((error) => {});
      this.vllamada = !this.utilizaVideoLlamada ? false : this.tipoTurno;
    },
  },
  watch: {
    date(val) {
      this.dateFormatted = this.formatDate(this.date);
    },
  },
  created() {
    this.vaciarModels();
  },
};
</script>
<style lang="scss">
.cada-dos-semanas {
  .v-input--checkbox {
    margin-top: 0.6rem;
    padding-top: 0;
    .v-input--selection-controls__input {
      margin-right: 2px;
    }
  }
}
</style>
