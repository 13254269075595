<template>
  <div>
    <v-card class="mb-2" tile>
      <v-card-title>
        <span class="title font-weight-light">Archivos</span>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="this.listaInformes"
        class="elevation-1 row-pointer"
        locale="es-ar"
        @click:row="seleccionVer"
        item-key="idInforme"
        :items-per-page="10"
        ref="myTable"
        :disable-sort="true"
        :footer-props="{
          itemsPerPageText: 'Filas por página',
        }"
      >
        <template v-slot:item.varios="{ item }">
          <v-simple-checkbox v-model="item.varios" disabled></v-simple-checkbox>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                medium
                color="blue"
                class="mr-3"
                v-on="on"
                v-bind="attrs"
                @click.stop="seleccionVer(item)"
                >mdi-magnify</v-icon
              >
            </template>
            <span>Ver Archivo</span>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                medium
                color="orange"
                class="mr-3"
                v-on="on"
                v-bind="attrs"
                @click.stop="enviarInforme(item)"
                >mdi-email</v-icon
              >
            </template>
            <span>Enviar Archivo por Mail</span>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                medium
                color="green"
                class="mr-3"
                v-on="on"
                v-bind="attrs"
                @click.stop="enviarInformeWA(item)"
                >mdi-whatsapp</v-icon
              >
            </template>
            <span>Enviar Archivo por Whats App</span>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                medium
                color="red"
                class="mr-3"
                v-on="on"
                v-bind="attrs"
                @click.stop="borrarInforme(item)"
                >mdi-delete</v-icon
              >
            </template>
            <span>Eliminar Archivo</span>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-show="item.extension !== 'varios'"
                medium
                color="purple"
                class="mr-3"
                v-on="on"
                v-bind="attrs"
                @click.stop="descargarInforme(item)"
                >mdi-download</v-icon
              >
            </template>
            <span>Decargar Archivo</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-card-actions>
        <div v-if="loading == true" class="loader mr-2"></div>
        <h4 v-if="loading == true">Buscando archivo</h4>
        <v-spacer></v-spacer>
        <v-btn color="success" @click.stop="nuevoInforme">Subir Archivo</v-btn>
        <v-btn color="warning" @click.stop="verImagenes(0)">
          <v-icon left class="mr-1">mdi-magnify</v-icon>Archivos Imagenes</v-btn
        >
      </v-card-actions>
      <NuevoInforme
        :visible="showNuevoInforme"
        :idPaciente="idPaciente"
        :paciente="paciente"
        @close="showNuevoInforme = false"
        @informeRegistrado="informeRegistrado"
      ></NuevoInforme>
      <EditarInforme
        :visible="showEditarInforme"
        :idPaciente="idPaciente"
        :idInforme="editedItem.idInforme"
        :paciente="paciente"
        @close="showEditarInforme = false"
        @informeModificado="informeModificado"
      ></EditarInforme>
      <EnviarInforme
        :visible="showEnviarInforme"
        :codInforme="editedItem.codInforme"
        :paciente="paciente"
        @close="showEnviarInforme = false"
      ></EnviarInforme>
      <EnviarInformeWA
        :visible="showEnviarInformeWA"
        :codInforme="editedItem.codInforme"
        :varios="editedItem.varios"
        :ruta="editedItem.ruta"
        :paciente="paciente"
        @close="showEnviarInformeWA = false"
      ></EnviarInformeWA>
      <VerInforme
        :visible="showVerInforme"
        :informe="editedItem"
        :paciente="paciente"
        @close="showVerInforme = false"
      ></VerInforme>
      <VerImagenes
        :visible="showVerImagenes"
        :images="listaImagenes"
        @close="showVerImagenes = false"
      ></VerImagenes>
    </v-card>
  </div>
</template>
<style scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
<script>
import axios from "../../axios-auth.js";
import NuevoInforme from "../HistClin/NuevoInforme.vue";
import VerInforme from "../HistClin/VerInforme.vue";
import EditarInforme from "../HistClin/EditarInforme.vue";
import EnviarInforme from "../HistClin/EnviarInforme.vue";
import EnviarInformeWA from "../HistClin/EnviarInformeWA.vue";
import VerImagenes from "../HistClin/VerImagenes.vue";
import * as FileSaver from "file-saver";

export default {
  data: (vm) => ({
    headers: [
      { text: "Fecha", value: "fechaInformeString" },
      { text: "Título", value: "titulo" },
      { text: "Descripción", value: "descripcion" },
      { text: "Tipo Archivo", value: "extension" },
      { text: "Profesional", value: "nomProfesional" },
      { text: "Origen", value: "origenString" },
      { text: "Inst. Externa", value: "nombreInstitucionExterna" },
      { text: "Mult. Archivos", value: "varios" },
      { text: "Acciones", value: "actions" },
    ],
    showNuevoInforme: false,
    showVerInforme: false,
    showEditarInforme: false,
    showEnviarInforme: false,
    showEnviarInformeWA: false,
    showVerImagenes: false,
    listaImagenes: [],
    editedItem: {},
    editedIndex: -1,
    loading: false,
  }),
  props: ["idPaciente", "lista", "paciente", "profsBuscar"],
  computed: {
    listaInformes: {
      set(value) {
        if (value != []) this.$emit("actualizarInformes");
      },
      get() {
        return this.lista;
      },
    },
  },
  methods: {
    seleccionVer(item) {
      if (item.varios == false) this.verInforme(item);
      else {
        this.verImagenes(item);
      }
    },
    verImagenes(flag) {
      if (flag == 0) {
        this.listaImagenes = [];
        var imagenes = this.listaInformes.filter(
          (x) =>
            x.extension == ".jpeg" ||
            x.extension == ".jpg" ||
            x.extension == ".png"
        );
        imagenes.forEach((image) => {
          this.listaImagenes.push(image.ruta);
        });
        this.showVerImagenes = true;
      } else {
        this.listaImagenes = flag.imagenesInformes;
        this.showVerImagenes = true;
      }
    },
    enviarInforme(item) {
      this.editedIndex = this.listaInformes.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.showEnviarInforme = true;
    },
    enviarInformeWA(item) {
      this.editedIndex = this.listaInformes.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.showEnviarInformeWA = true;
    },
    borrarInforme(item) {
      if (this.profsBuscar.some((o) => o.idProfesional == item.idProfesional)) {
        this.$swal({
          title: "Borrar Archivo",
          text: "¿Desea borrar el archivo?",
          icon: "success",
          background: "#ececec",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
          showCancelButton: true,
          confirmButtonText: "Si",
          cancelButtonText: "No",
          showCloseButton: true,
          showLoaderOnConfirm: true,
        }).then((result) => {
          if (result.value) {
            //this.$swal('Turno g', 'You successfully deleted this file', 'success')

            const idInforme = item.idInforme;
            let self = this;
            axios
              .get("/HistoriaClinica/BorrarInforme?", {
                params: {
                  idInforme: idInforme,
                },
              })
              .then((response) => {
                self.showAlert({
                  icon: "success",
                  title: "Informe borrado exitosamente",
                  vm: self,
                  timer: 1700,
                });
                setTimeout(self.buscarInformes(), 1700);
              })
              .catch((error) => {
                self.showAlert({
                  icon: "error",
                  title: "El informe no pudo ser borrado",
                  vm: this,
                  timer: 1700,
                });
              });
          } else {
            //this.$swal('Cancelled', 'Your file is still intact', 'info')
          }
        });
      } else {
        this.showAlert({
          icon: "error",
          title:
            "El informe fue creado por un profesional que usted no tiene asociado. No puede eliminar el informe",
          vm: this,
          timer: 3000,
        });
      }
    },
    editarInforme(item) {
      if (this.profsBuscar.some((o) => o.idProfesional == item.idProfesional)) {
        this.editedIndex = this.listaInformes.indexOf(item);
        this.editedItem = Object.assign({}, item);
        this.showEditarInforme = true;
      } else {
        this.showAlert({
          icon: "error",
          title:
            "El informe fue creado por un profesional que usted no tiene asociado. No puede editar el informe",
          vm: this,
          timer: 3000,
        });
      }
    },
    showAlert(alertInfo) {
      alertInfo.vm.$swal.fire({
        icon: alertInfo.icon,
        title: alertInfo.title,
        showConfirmButton: false,
        background: "#ececec",
        timer: alertInfo.timer,
      });
    },
    nuevoInforme() {
      this.showNuevoInforme = true;
    },
    verInforme(item) {
      this.editedIndex = this.listaInformes.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.showVerInforme = true;
    },
    informeRegistrado() {
      this.buscarInformes();
      this.showNuevoInforme = false;
    },
    informeModificado() {
      this.buscarInformes();
      this.showEditarInforme = false;
    },
    buscarInformes() {
      this.listaInformes = 1;
    },
    descargarInforme(item) {
      var editedItem = Object.assign({}, item);
      let nombrePaciente = "";

      axios
        .get("/Paciente/BuscarDatosPaciente", {
          params: {
            idpaciente: editedItem.idPaciente,
          },
        })
        .then((response) => {
          console.log(response.data);
          nombrePaciente =
            response.data.apellidos + ", " + response.data.nombres;
        });

      axios
        .get("/HistoriaClinica/ObtenerArchivo", {
          params: {
            ruta: editedItem.ruta,
          },
          responseType: "arraybuffer",
        })
        .then((response) => {
          const file = new Blob([response.data]);
          // process to auto download it
          const fileURL = URL.createObjectURL(file);
          const link = document.createElement("a");
          link.href = fileURL;
          link.download =
            "Informe " +
            nombrePaciente +
            "-" +
            editedItem.fechaInformeString +
            editedItem.extension;
          link.click();
        });
    },
  },
  components: {
    NuevoInforme,
    VerInforme,
    EditarInforme,
    EnviarInforme,
    EnviarInformeWA,
    VerImagenes,
  },
};
</script>
