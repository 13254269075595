<template>
  <v-dialog
    :max-width="maxWidth"
    v-model="show"
    persistent
    transition="dialog-bottom-transition"
  >
    <v-form ref="form" v-model="valid" :lazy-validation="lazy">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Enviar Archivo</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-alert
            color="blue"
            dark
            outlined
            v-if="paciente.celular == '' || paciente.celular == null"
            >El paciente no tiene registrado un celular en su ficha, puede
            introducir uno a continuación.</v-alert
          >
          <v-row dense>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="celular"
                prepend-icon="mdi-whatsapp"
                label="Celular"
                :rules="celularRules"
                hint="Ingrese el celular con la característica, sin 0, sin 15 y sin guiones. Ej: 1135036549"
                dense
                maxlength="80"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" :disabled="!valid" @click.stop="enviarMensaje"
            >Enviar Mensaje</v-btn
          >

          <v-btn color="error" @click.stop="show = false">Salir</v-btn>
        </v-card-actions>
        <v-card-text>
          <v-row dense>
            <v-col cols="12" sm="9" md="9">
              <h3 v-if="!envioDirecto">
                El código que se enviará al paciente para que pueda visualizar
                el archivo es: {{ codInforme }}
              </h3>
              <h3>Se enviará el archivo directo al celular del paciente</h3>
            </v-col>
            <v-col cols="12" sm="9" md="9" v-if="!envioDirecto">
              <h3>
                Puede utilizar este código y enviarseló al paciente mediante
                otra vía de comunicación
              </h3>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<style scoped>
.multi-line {
  white-space: pre-line;
}
.v-dialog__content {
  align-items: left;
  justify-content: left;
}
</style>
<script>
import axios from "../../axios-auth.js";

export default {
  data: (vm) => ({
    valid: false,
    lazy: false,
    maxWidth: "100%",
    celular: "",
    celularRules: [(v) => !v || v.length == 10 || "Debe tener 10 caracteres"],
  }),
  props: ["visible", "codInforme", "paciente", "varios", "ruta"],
  computed: {
    show: {
      get() {
        if (this.visible) {
          this.setearModels();
        }
        return this.visible;
      },
      set(value) {
        if (!value) {
          document.getElementsByClassName("v-dialog--active")[0].scrollTop = 0;
          this.$emit("close");
        }
      },
    },
    envioDirecto() {
      return (
        (this.$store.state.usuario.institucion.idInstitucion === 1 ||
          this.$store.state.usuario.institucion.idInstitucion === 289) &&
        !this.varios
      );
    },
  },

  methods: {
    setearModels() {
      this.celular = this.paciente.celular;
      if (this.$store.state.videollamada == true) this.maxWidth = "68%";
      else this.maxWidth = "100%";
    },
    enviarMensaje() {
      this.$swal({
        title: "Enviar whats app con código de archivo al paciente",
        text: "¿Desea enviar un mensaje de whats app al paciente con el código para visualizar el archivo?",
        icon: "success",
        type: "success",
        showCancelButton: true,
        background: "#ececec",
        confirmButtonText: "Si",
        cancelButtonText: "No",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          //this.$swal('Turno g', 'You successfully deleted this file', 'success')

          if (this.envioDirecto) {
            this.enviarMensajeDirecto();
          } else {
            this.enviarMensajePortal();
          }
        } else {
          //this.$swal('Cancelled', 'Your file is still intact', 'info')
        }
      });
    },
    enviarMensajePortal() {
      const datos = {
        codInforme: this.codInforme,
        celular: this.celular,
        idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
        idPaciente: this.paciente.idPaciente,
      };

      let self = this;
      axios
        .get("/HistoriaClinica/EnviarInformeWA?", {
          params: {
            codInforme: datos.codInforme,
            celular: datos.celular,
            idInstitucion: datos.idInstitucion,
            idPaciente: datos.idPaciente,
          },
        })
        .then((response) => {
          self.showAlert({
            icon: "success",
            title:
              "Se envió un mensaje al celular indicado para que el paciente pueda visualizar el archivo desde el Portal del Paciente.",
            vm: self,
            timer: 4200,
          });
          self.show = false;
        })
        .catch((error) => {
          self.showAlert({
            icon: "error",
            title:
              "El mensaje no pudo ser enviado. Intente mas tarde por favor.",
            vm: this,
            timer: 3000,
          });
        });
    },
    async enviarMensajeDirecto() {
      const payload = {
        ruta: this.ruta,
        nombrePaciente: `${this.paciente.nombres} ${this.paciente.apellidos}`,
        celular: this.celular,
      };
      try {
        await axios.post("/HistoriaClinica/EnviarInformeWADirecto", payload);
        this.showAlert({
          icon: "success",
          title: "Archivo enviado correctamente",
          vm: this,
          timer: 4200,
        });
        this.show = false;
      } catch (error) {
        this.showAlert({
          icon: "error",
          title: "El archivo no pudo ser enviado. Intente mas tarde por favor.",
          vm: this,
          timer: 3000,
        });
      }
    },
    showAlert(alertInfo) {
      alertInfo.vm.$swal.fire({
        icon: alertInfo.icon,
        title: alertInfo.title,
        showConfirmButton: false,
        background: "#ececec",
        timer: alertInfo.timer,
      });
    },
  },
};
</script>
